import { smoothScroll } from "./components/smooth-scroll";
import { mobileNav } from "./components/mobile-nav";
import { stickyNav } from "./components/stickyNav";
import { carousel } from "./components/carousel";
import { dropdown } from "./components/dropdown";
import { accordion } from "./components/accordion";
import { retailFiltering } from "./components/retailFiltering";
import { salesRepFiltering } from "./components/salesRepFiltering";
import { productScroll } from "./components/productScroll";

smoothScroll();
mobileNav();
stickyNav();
carousel();
dropdown();
accordion();
retailFiltering();
salesRepFiltering();
productScroll();