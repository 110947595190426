import $ from 'jquery';
import 'slick-carousel';

export const productScroll = () => {
    console.log('slick loaded');  
    $('.productSlider').slick({
        centerMode: true,
        centerPadding: '0',
        slidesToShow: 5,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 3
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 1
            }
          }
        ]
      });
};
