import * as $ from 'jquery';

export const mobileNav = () => {
    $(document).ready(function() {
        var $window = $(window),
        $html = $('html');

        $window.resize(function resize(){
            if ($window.width() > 1024) {
                $('.mobileNav').removeClass('is-active');
                $('#mainView').removeClass('navActive');
            }
        }).trigger('resize');


        $('.hasSubnav a').click(function(e) {
            e.preventDefault();
        
            // parseInt(Math.abs(clickPosition - pos) * self.options.itemsDelayInterval)
            var clickedIndex = $(this).closest('li').index();
            var id = $(this).attr('data-id');

            console.log(id);
        
            $('.nav-wrapper ul').each(function(index, item) {
                $(item).find('li').each(function(subIndex, subItem) {
                    // $(subItem).css('animation-delay', parseInt(Math.abs(clickedIndex - subIndex) * 60) + 'ms');
                    subItem.style.animationDelay = parseInt(Math.abs(clickedIndex - subIndex) * 60) + 'ms';
                });
            });
        
            $(e.target).closest('ul').find('li').removeClass('animate-inFromLeft').addClass('animate-outToLeft');
        
            $('.nav-wrapper').find('#' + id).addClass('subActive').find('li').removeClass('animate-outToRight').addClass('subActive animate-inFromRight');
        });
        
        $('.nav-wrapper .back a').click(function(e) {
            e.preventDefault();
            
            $(this).closest('ul').removeClass('subActive');
            $('.animate-outToLeft').removeClass('animate-outToLeft').addClass('animate-inFromLeft');
            $('.animate-inFromRight').removeClass('animate-inFromRight').addClass('animate-outToRight');
        });

        $('.hamburger').on('click',function() {
            $('.subnav').removeClass('subActive');
            $('#mainView').toggleClass('navActive');
            $('.mobileNav').toggleClass('is-active');
            $( this ).toggleClass( 'is-active' );
            $('.nav-wrapper li').removeClass('animate-inFromLeft animate-outToLeft animate-inFromRight animate-outToRight');
        });
        
        // $('.mobileNav').on('change', function() {
        //     $('.mobileNav.is-active ul.subnav').removeClass('uk-dropdown');
        // });

        $('.mobileNav .dropdown-toggle').on('click', function(e) {
            console.log('clicked');
            $(this).toggleClass('active').toggleClass('subActive');
            $(this).next('.hiddenNav').toggle('active');
        });

        $('.primaryNav .dropdown-toggle').on('click', function(e) {
            $('.hiddenNav').toggle();
            $(this).parent().toggleClass('open');
            
        });
        $('.nav-link.dropdown-toggle').on('show.bs.dropdown', function () {
            $(this).addClass('test');
          })
    });
    // $('.nav').on('show.bs.dropdown', function () {
    //     // do something...
    //     $('.nav-item a').addClass('disabled');
    //     console.log('dropdown open');
    // });
    $('li.dropdown :first-child').on('click', function() {
        var $el = $(this).parent();
        if ($el.hasClass('open')) {
            console.log('here');
            var $a = $el.children('a.dropdown-toggle');
            if ($a.length && $a.attr('href')) {
                location.href = $a.attr('href');
            }
        }
    });
};
