export const accordion = () => {
    window.addEventListener('load', function () {
        const buttons = document.querySelectorAll('.accordion-button');

        if (0 === buttons.length) {
            return;
        }

        const hide = el => {
            el.style.height = '0px';
            el.setAttribute('aria-expanded', 'false');
        };

        for (let i = 0; i < buttons.length; i++) {
            const content = document.getElementById(buttons[i].getAttribute('aria-controls'));
            const height = content.clientHeight;

            hide(content);

            buttons[i].addEventListener('click', function (e) {
                e.preventDefault();
                for (let j = 0; j < buttons.length; j++) {
                    const controlledByButton = document.getElementById(buttons[j].getAttribute('aria-controls'));
                    if (e.currentTarget !== buttons[j] || e.currentTarget.classList.contains('expanded')) {
                        buttons[j].classList.remove('expanded');
                        hide(controlledByButton);
                    } else {
                        buttons[j].classList.add('expanded');
                        content.style.height = `${height}px`;
                        content.setAttribute('aria-expanded', 'true');
                    }
                }
            });
        }
    });
};
