import * as $ from 'jquery';

export const retailFiltering = () => {
    $(document).ready(function() {
        $('.region').hide();
        $('.regional-logo-container').hide();
        var data = '';
        $('#retailSelect').on('change', function(event) {
            var $region = $(this).val();

            $('.region').hide();
            $('.region.'+$region).show();

            console.log($('.region:not([style*="display: none"])').length);
            
            if($('.region:not([style*="display: none"])').length > 0) {
                $('.regional-logo-container').show();
            } else {
                $('.regional-logo-container').hide();
            }

            console.log($region);
            console.log('.region.'+$region);

        });
    });
};
