import * as $ from 'jquery';

export const salesRepFiltering = () => {
    $(document).ready(function() {
        $('.region').hide();
        var data = '';
        $('#salesRegionSelect').on('change', function(event) {
            var $region = $(this).val();

            $('.region').hide();
            $('.region.'+$region).show();

            console.log($region);
            console.log('.region.'+$region);

        });
    });
};
